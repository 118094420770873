<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">订单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input v-model="searchData.courseName" type="text" size="small" clearable placeholder="请输入课程名称" />
            </div>
            <div title="订单状态" class="searchboxItem ci-full">
              <span class="itemLabel">订单状态:</span>
              <el-select v-model="searchData.orderStatus" placeholder="请选择订单状态" size="small" clearable>
                <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="订单日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">订单日期:</span>
              <el-date-picker clearable style="width: 260px" size="small" format="yyyy-MM-dd"
                v-model="searchData.orderTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <el-button style="margin-left: 20px" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />
              <el-table-column label="课程封面" align="center" width="260px">
                <template slot-scope="scope">
                  <img style="width: 250px; height: 128px; border-radius: 10px" :src="scope.row.logo" alt="" />
                </template>
              </el-table-column>
              <el-table-column label="课程名称" align="center" prop="productName" show-overflow-tooltip min-width="160" />
              <el-table-column label="课时" align="center" prop="lessonNum" show-overflow-tooltip width="100" />
              <el-table-column label="单价(元)" align="center" prop="productPrice" show-overflow-tooltip width="120" />
              <el-table-column label="数量" align="center" prop="productCnt" show-overflow-tooltip width="100" />
              <el-table-column label="支付方式" align="center" prop="paymentMethod" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.paymentMethod == 3">微信</span>
                  <span v-else-if="scope.row.paymentMethod == 4">支付宝</span>
                  <span v-else-if="scope.row.paymentMethod == 5">银行对公</span>
                  <span v-else-if="scope.row.paymentMethod == 6">第三方</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="订单编号" align="center" prop="orderSn" show-overflow-tooltip width="160" />
              <el-table-column label="订单日期" align="center" prop="createTime" show-overflow-tooltip width="140" />
              <el-table-column label="订单状态" align="center" prop="orderStatus" show-overflow-tooltip width="120">
                <template slot-scope="scope">
                  {{ $setDictionary("ORDER_STATUS", scope.row.orderStatus) }}
                </template>
              </el-table-column>
              <el-table-column label="课程来源" align="center" prop="compName" show-overflow-tooltip width="160" />
              <el-table-column label="订单金额" align="center" prop="orderMoney" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                  <span style="color: red; font-size: 16px; font-weight: 600">￥{{ scope.row.orderMoney }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="240px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 7px 10px"
                    @click="seeCurriculumInfo(scope.row)">查看课程</el-button>
                  <el-button v-if="[0].includes(scope.row.orderStatus)" type="text" size="mini"
                    style="padding: 7px 10px" @click="cancellationOfOrder(scope.row)">取消订单</el-button>
                  <el-button v-if="[0].includes(scope.row.orderStatus) && ![5].includes(scope.row.paymentMethod)"
                    type="text" size="mini" style="padding: 7px 10px" @click="continueToPay(scope.row)">继续支付</el-button>
                  <el-button v-if="[1, 5].includes(scope.row.orderStatus)" type="text" size="mini"
                    style="padding: 7px 10px" @click="refund(scope.row)">退款</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="onUploadMaterials(scope.row)">上传材料</el-button>
                  <el-button v-if="[0].includes(scope.row.orderStatus) && [5, 6].includes(scope.row.paymentMethod)"
                    type="text" size="mini" style="padding: 7px 10px"
                    @click="uploadVoucher(scope.row.orderId)">上传支付凭证</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
        <PartnerCourseTable ref="PartnerCourseTable" />
      </div>
    </div>
    <!-- 继续支付 -->
    <el-dialog title="继续支付" :visible.sync="weixindialogVisible" :before-close="closeweixindialogVisible" width="35%">
      <div class="jxzf_1">课程名称：{{ jxzfLjData.jxzf_1 }}</div>
      <div class="jxzf_2">
        订单支付金额：<span>￥{{ jxzfLjData.jxzf_2 }}</span>
      </div>
      <div class="jxzf_3">订单时间：{{ jxzfLjData.jxzf_3 }}</div>
      <div id="qrcode" ref="qrcode"></div>
      <div class="jxzf_4">
        请打开微信 - 扫一扫<br />
        扫描二维码完成付款
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button class="bgc-bv" size="small" @click="closeweixindialogVisible">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 上传材料 -->
    <el-dialog title="上传材料" :visible="formUploadMaterials.dialogVisible" :before-close="closeUploadMaterials"
      width="50%">
      <el-row>
        <el-col :span="12">
          <span style="width: 90px; text-align: right">机构名称：</span>
          <span>{{ formUploadMaterials.compData.compName }}</span>
        </el-col>
        <el-col :span="12">
          <span style="width: 100px">培训有效期：</span>
          <span style="width: 250px">
            {{ formUploadMaterials.compData.startDate || "--" }} 至 {{ formUploadMaterials.compData.endDate || "--" }}
          </span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col :span="12">
          <span style="width: 90px; text-align: right">联系人：</span>
          <span>{{ formUploadMaterials.compData.compUser || "--" }}</span>
        </el-col>
        <el-col :span="12">
          <span style="width: 100px">联系方式：</span>
          <span style="width: 250px">{{ formUploadMaterials.compData.compPhone || "--" }}</span>
        </el-col>
      </el-row>
      <el-form ref="form" :model="formUploadMaterials" label-width="100px" style="margin-top: 20px">
        <el-form-item label="单位介绍信：">
          <el-upload class="upload-demo" action drag multiple show-file-list
            :file-list="formUploadMaterials.unitIntroductionLetter" accept=".png,.jpg,.jpeg,.pdf"
            :on-remove="(el, list) => removeFile(el, list, 1)" :on-preview="previewFile"
            :http-request="(el) => uploadFile(el, 1)">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传图片或PDF文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="申请表：">
          <el-upload class="upload-demo" action drag multiple show-file-list
            :file-list="formUploadMaterials.applicationForm" accept=".png,.jpg,.jpeg,.pdf"
            :on-remove="(el, list) => removeFile(el, list, 2)" :on-preview="previewFile"
            :http-request="(el) => uploadFile(el, 2)">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传图片或PDF文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="其他材料：">
          <el-upload class="upload-demo" action drag multiple show-file-list
            :file-list="formUploadMaterials.otherMaterials" accept=".png,.jpg,.jpeg,.pdf"
            :on-remove="(el, list) => removeFile(el, list, 3)" :on-preview="previewFile"
            :http-request="(el) => uploadFile(el, 3)">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传图片或PDF文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="btn-box flexcc">
        <el-button @click="closeUploadMaterials" class="bgc-bv">取 消</el-button>
        <el-button class="bgc-bv" @click="doAddSave">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 上传材料 - 预览 -->
    <el-dialog title="上传材料 - 预览" :visible.sync="formUploadMaterialsPreview.dialogVisible" @close="uploadLodClose"
      :modal="false" width="50%" top="2%" center v-dialogDrag>
      <div class="ovy-a" style="height: 600px">
        <div id="pdf-cert1" style="height: 600px"
          v-if="formUploadMaterials.fileType == 'pdf' || formUploadMaterials.fileType == 'PDF'"></div>
        <div v-else>
          <img :src="formUploadMaterialsPreview.ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <!-- 上传凭证 - 弹框 -->
    <el-dialog title="上传凭证" :visible.sync="uploadVoucherData.dialogVisible" width="38%" class="toVoidLoding">
      <div style="margin: 10 0 10px;display: flex;flex-direction: column;">
        <h2>请您将电汇(对公支付)汇款成功的凭证或付款订单页面截图或下载pdf。</h2>
        <el-upload class="upload-demo upload-btn" :action="actionUrl" :on-error="handleError"
          :on-success="handleSuccess" :on-change="uploadChange1" :show-file-list="false" :auto-upload="false" drag>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <p style="color:red;font-size:1rem">注意:上传的凭证要求格式为pdf或jpg、png图片清晰,能清楚反应您付款的情况。</p>
        <div v-if="uploadVoucherData.fileName" style="margin-top: 10px; margin-left: 10px">
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span style="color: #409eff;margin-top:10px">
              {{ uploadVoucherData.fileName }}
              <a @click="lookExl" style="color: #409eff; margin: 0 15px">预览</a>
              <a @click="reomveExl" style="color: red; margin: 0 15px">删除</a>
            </span>
          </div>
        </div>
        <div v-else>
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" class="bgc-bv" round @click="uploadfileClose">取 消</el-button>
        <el-button type="primary" size="small" class="bgc-bv" round @click="uploadfileDetermine">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上传凭证 - 预览附件 - 弹框 -->
    <el-dialog title="预览" :visible.sync="uploadVoucherDataPreviewFileData.dialogVisible" @close="uploadLodClose"
      width="50%" top="2%" center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px"
          v-if="uploadVoucherData.extension == 'PDF' || uploadVoucherData.extension == 'pdf'"></div>
        <div v-else>
          <img :src="uploadVoucherDataPreviewFileData.ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <!-- 第三方 - 退款- 弹框 -->
    <el-dialog title="确认退款订单" :visible.sync="threeSquareRefundData.dialogVisible" @close="threeSquareRefundDataClose"
      width="600px" center>
      <el-form :model="threeSquareRefundData" :rules="threeSquareRefundData.rules" ref="formData_2"
        size="small" label-width="120px">
        <el-form-item label="订单编号：">
          <span>{{ threeSquareRefundData.orderSn }}</span>
        </el-form-item>
        <el-form-item label="订单日期：">
          <span>{{ threeSquareRefundData.createTime }}</span>
        </el-form-item>
        <el-form-item label="订单人数：">
          <span>{{ threeSquareRefundData.productCnt }}</span>
        </el-form-item>
        <el-form-item label="订单金额(元)：">
          <span class="text_1">{{ threeSquareRefundData.orderMoney }}</span>
        </el-form-item>
        <el-form-item label="可退款人数：">
          <el-input-number class="input_1" v-model="threeSquareRefundData.refundCntC"
            :precision="0" :min="1" :max="threeSquareRefundData.productCnt - threeSquareRefundData.refundCnt - threeSquareRefundData.usedCount"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="threeSquareRefundDataClose">取 消</el-button>
        <el-button size="small" class="bgc-bv" @click="threeSquareRefundDataOpenOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import QRCode from "qrcodejs2"; // 引入qrcode
import pdf from "pdfobject";
import { resetKeepAlive, acceptTypeImg } from "@/utils/common";
export default {
  name: "Partner/partnerList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    let currentDate = new Date();
    let startDate = currentDate.getFullYear() + '-01-01';
    let endMonth = currentDate.getMonth() + 1;
    let endDay = currentDate.getDate();
    if (endMonth < 10) {
      endMonth = "0" + endMonth;
    }
    if (endDay < 10) {
      endDay = "0" + endDay;
    }
    let endDate = currentDate.getFullYear() + "-" + endMonth + "-" + endDay;
    return {
      // 检索数据
      searchData: {
        orderTime: [startDate, endDate],
        courseName: "", // 课程名称
        orderStatus: "", // 订单状态
      },
      // 订单状态 - 下拉数据
      orderStatusList: [],
      // 订单编号
      orderSn: "",
      // 微信支付弹框
      weixindialogVisible: false,
      // 实时获取购买是否成功
      getInfosSetInterval: null,
      // 继续支付 - 微信：显示友好的字段
      jxzfLjData: {
        jxzf_1: "",
        jxzf_2: "",
        jxzf_3: "",
      },
      // 上传材料 - 弹框数据
      formUploadMaterials: {
        dialogVisible: false, // 弹窗状态
        projectData: {}, // 班级数据
        compData: {}, // 机构数据
        fileType: "", // 文件类型
        unitIntroductionLetter: [], // 单位介绍信列表
        applicationForm: [], // 申请表
        otherMaterials: [], // 其他材料
      },
      // 上传材料 - 预览材料 - 弹框数据
      formUploadMaterialsPreview: {
        dialogVisible: false, // 弹窗状态
        ImgSrc: "", // 预览图片的地址
      },
      // 上传凭证 - 弹框数据
      uploadVoucherData: {
        dialogVisible: false, //弹框状态
        orderId: "", //订单id
        fileName: "", //文件名称
        voucherUrl: "", //文件Url
        voucherKey: "", //文件Key
        extension: "", //上传类型
      },
      // 上传凭证 - 预览材料 - 弹框数据
      uploadVoucherDataPreviewFileData: {
        dialogVisible: false, //弹框状态
        ImgSrc: "", //预览图片Src路径
      },
      // 第三方退款 - 弹框数据
      threeSquareRefundData: {
        dialogVisible: false, //弹框状态
        refundCntC: 1, // 前台用来传 可退款人数(为啥单写一个字段:后台他们列表和入参都这个，造成前端计算出现bug)
      },
    };
  },
  created() {
    this.getOrderStatusList();
    this.getTableHeight();
  },
  methods: {
    // 获取 - 购买状态下拉码值
    getOrderStatusList() {
      const evaluateCertPaymentList = this.$setDictionary(
        "ORDER_STATUS",
        "list"
      );
      const CertPaymentList = [];
      for (const key in evaluateCertPaymentList) {
        CertPaymentList.push({
          value: key,
          label: evaluateCertPaymentList[key],
        });
      }
      this.orderStatusList = CertPaymentList;
    },
    // 获取 - 订单列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.courseName) {
        params.courseName = this.searchData.courseName;
      }
      if (this.searchData.orderStatus) {
        params.orderStatus = this.searchData.orderStatus;
      }
      if (this.searchData.orderTime) {
        params.startDate = this.searchData.orderTime[0];
        params.endDate = this.searchData.orderTime[1];
      }
      this.doFetch(
        {
          url: "/order/drp-order-main/hospital/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 查看课程
    seeCurriculumInfo(row) {
      this.$router.push({
        path: "/web/coursesIboughtInfo",
        query: {
          courseId: row.courseId,
          projectCourseId: row.projectCourseId,
        },
      });
    },
    // 取消订单
    cancellationOfOrder(row) {
      this.$post(
        "/order/drp-order-main/close",
        {
          orderSn: row.orderSn,
        },
        true,
        3000,
        2
      ).then((res) => {
        if (res.status == "0") {
          this.$message({
            message: "该订单已关闭",
            type: "success",
          });
          this.getData();
        }
      });
    },
    // 继续支付
    continueToPay(row) {
      // 微信
      if (row.paymentMethod == 3) {
        this.weixindialogVisible = true;
        this.$post(
          "/wechat/pay/repay",
          {
            orderSn: row.orderSn,
          },
          3000,
          true,
          2
        ).then((res) => {
          this.orderSn = row.orderSn;
          this.jxzfLjData.jxzf_1 = row.productName;
          this.jxzfLjData.jxzf_2 = row.orderMoney;
          this.jxzfLjData.jxzf_3 = row.createTime;
          this.crateQrcode(res.data.codeURL);
        });
      }
      // 支付宝
      if (row.paymentMethod == 4) {
        const baseURL =
          process.env.VUE_APP_URL == "development"
            ? "http://192.168.3.112:9000/question-lib"
            : process.env.NODE_ENV == "development"
              ? "http://192.168.3.112:9000/question-lib"
              : "https://anzhi.bjsrxx.com/question-lib";
        window.location.href =
          baseURL +
          "/ali/pay/repay?orderSn=" +
          row.orderSn +
          "&token=" +
          this.$store.state.token +
          "&returnUrl=" +
          encodeURIComponent(
            location.protocol +
            "//" +
            location.host +
            "/#/web/courseCenterInfo/paySuccess"
          );
      }
    },
    // 生成二维码
    crateQrcode(codeURL) {
      this.$refs.qrcode.innerHTML = "";
      this.qr = new QRCode("qrcode", {
        width: 150,
        height: 150, // 高度
        text: codeURL, // 二维码内容
        render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      });
      this.getInfosSetInterval = setInterval(() => {
        this.getInfos();
      }, 3000);
    },
    // 实时获取购买是否成功
    getInfos() {
      this.$post(
        "/order/drp-order-main/detail",
        {
          orderSn: this.orderSn,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.data.orderStatus == 1) {
          this.weixindialogVisible = false;
          clearInterval(this.getInfosSetInterval); // 清除定时器
          this.getInfosSetInterval = null;
          let jsonData = JSON.stringify(res.data);
          this.$router.push({
            path: "/web/courseCenterInfo/paySuccess",
            query: {
              jsonData,
            },
          });
        }
      });
    },
    // 取消微信支付
    closeweixindialogVisible() {
      this.weixindialogVisible = false;
      clearInterval(this.getInfosSetInterval); // 清除定时器
      this.getInfosSetInterval = null;
    },
    // 退款
    refund(row) {
      // 如果是第三方支付
      if (row.paymentMethod == 6) {
        this.threeSquareRefundData = { ...this.threeSquareRefundData, ...row };
        this.threeSquareRefundData.dialogVisible = true;
      } else {
        this.$post(
          "/order/drp-order-main/refund/apply",
          {
            orderSn: row.orderSn,
          },
          true,
          3000,
          2
        )
          .then((res) => {
            if (res.status == "0") {
              this.$message({
                message: "您已成功申请退款",
                type: "success",
              });
              this.getData(this.pageNum);
            } else {
              this.$message({
                message: res.data.message,
                type: "warning",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: err.data.message,
              type: "warning",
            });
          });
      }
    },
    // 第三方退款 - 弹框 - 确定
    threeSquareRefundDataOpenOk(){
      this.$post(
        "/order/drp-order-refund/apply",
        {
          orderId: this.threeSquareRefundData.orderId,
          refundCnt: this.threeSquareRefundData.refundCntC
        },
        true,
        3000,
        2
      )
        .then((res) => {
          if (res.status == "0") {
            this.$message({
              message: "您已成功申请退款",
              type: "success",
            });
            this.getData(this.pageNum);
            this.threeSquareRefundDataClose();
          } else {
            this.$message({
              message: res.data.message,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "warning",
          });
        });
    },
    // 第三方退款 - 弹框关闭
    threeSquareRefundDataClose(){
      this.threeSquareRefundData = this.$options.data().threeSquareRefundData;
    },
    // 上传材料
    onUploadMaterials(e) {
      this.formUploadMaterials.projectData = e;
      this.formUploadMaterials.dialogVisible = true;
      let data = {
        projectCourseId: e.projectCourseId, // 班级课程id
        projectId: e.projectId, // 班级id
      };
      this.$post("/biz/projectCourse/material/companyInfo", data)
        .then((res) => {
          ;
          if (res.status == 0) {
            res.data.startDate = new Date(res.data.startDate).Format(
              "yy年MM月dd日"
            );
            res.data.endDate = new Date(res.data.endDate).Format(
              "yy年MM月dd日"
            );
            if (res.data.unitIntroductionLetter) {
              res.data.unitIntroductionLetter.forEach((element) => {
                element.name = element.pictureName;
              });
            }
            if (res.data.otherMaterials) {
              res.data.otherMaterials.forEach((element) => {
                element.name = element.pictureName;
              });
            }
            if (res.data.applicationForm) {
              res.data.applicationForm.forEach((element) => {
                element.name = element.pictureName;
              });
            }
            this.formUploadMaterials.compData = res.data;
            this.formUploadMaterials.unitIntroductionLetter = res.data.unitIntroductionLetter || []; // 单位介绍信列表
            this.formUploadMaterials.otherMaterials = res.data.otherMaterials || []; // 其他材料
            this.formUploadMaterials.applicationForm = res.data.applicationForm || []; // 申请表
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 上传材料 - 上传文件
    uploadFile(res, type) {
      if (acceptTypeImg(res)) {
        this.$message.error("上传文件的大小不能超过 10MB!");
        return false;
      } else {
        let formData = new FormData();
        let fileNmae = res.file.name; // 文件名
        let extension = fileNmae.replace(/.+\./, ""); // 文件类型
        if (
          !".png,.jpg,.jpeg,.bmp,.gif,.pdf,.PDF".includes(
            extension[extension.length - 1]
          )
        ) {
          this.$message.warning({
            message: "只能上传图片与pdf格式的文件！",
            duration: 1000,
          });
          return false;
        }

        formData.append("folder ", "QUESTION");
        formData.append("file ", res.file);
        formData.append("fileType ", extension);

        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            if (type == 1) {
              this.formUploadMaterials.unitIntroductionLetter.push({
                pictureKey: result.data.fileKey,
                pictureType: "20",
                pictureUrl: result.data.fileURL,
                pictureName: res.file.name,
                name: res.file.name,
                url: result.data.fileURL,
              });
            }

            if (type == 2) {
              this.formUploadMaterials.applicationForm.push({
                pictureKey: result.data.fileKey,
                pictureType: "10",
                pictureUrl: result.data.fileURL,
                pictureName: res.file.name,
                name: res.file.name,
                url: result.data.fileURL,
              });
            }

            if (type == 3) {
              this.formUploadMaterials.otherMaterials.push({
                pictureKey: result.data.fileKey,
                pictureType: "30",
                pictureUrl: result.data.fileURL,
                pictureName: res.file.name,
                name: res.file.name,
                url: result.data.fileURL,
              });
            }
            console.log(this.formUploadMaterials)
          })
          .catch((err) => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 上传材料 - 删除文件
    removeFile(file, fileList, type) {
      if (type == 1) {
        this.formUploadMaterials.unitIntroductionLetter = fileList;
      }
      if (type == 2) {
        this.formUploadMaterials.applicationForm = fileList;
      }
      if (type == 3) {
        this.formUploadMaterials.otherMaterials = fileList;
      }
    },
    // 上传材料 - 预览文件
    previewFile(res) {
      let extension = res.pictureKey.replace(/.+\./, "");
      this.formUploadMaterials.fileType = extension;
      if (extension == "pdf" || extension == "PDF") {
        this.$nextTick(() => {
          pdf.embed(res.pictureUrl, "#pdf-cert1");
        });
      } else {
        this.formUploadMaterialsPreview.ImgSrc = res.pictureUrl;
      }
      this.formUploadMaterialsPreview.dialogVisible = true;
    },
    // 上传材料 - 关闭弹窗
    closeUploadMaterials() {
      this.formUploadMaterials = this.$options.data().formUploadMaterials;
    },
    // 上传材料 - 确定
    doAddSave() {
      let data = {
        projectId: this.formUploadMaterials.projectData.projectId,
        projectCourseId: this.formUploadMaterials.projectData.projectCourseId,
        otherMaterials: this.formUploadMaterials.otherMaterials,
        applicationForm: this.formUploadMaterials.applicationForm,
        unitIntroductionLetter: this.formUploadMaterials.unitIntroductionLetter,
      };
      this.$post("/biz/projectCourse/material/update", data)
        .then((res) => {
          ;
          if (res.status == 0) {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.closeUploadMaterials();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 上传支付凭证
    uploadVoucher(orderId) {
      this.uploadVoucherData.dialogVisible = true;
      this.uploadVoucherData.orderId = orderId;
      this.getfileList(orderId);
    },
    // 上传支付凭证 - 上传文件
    uploadChange1(file) {
      this.uploadVoucherData.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      this.uploadVoucherData.extension =
        (extension != "pdf" && extension != "PDF") ? "IMAGE" : "PDF";
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.uploadVoucherData.voucherUrl = result.data.fileURL;
          this.uploadVoucherData.voucherKey = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    // 上传支付凭证 - 取消
    uploadfileClose() {
      this.uploadVoucherData = this.$options.data().uploadVoucherData;
    },
    // 上传支付凭证 - 确定
    uploadfileDetermine() {
      let parmar = {
        orderId: this.uploadVoucherData.orderId,
        fileType: this.uploadVoucherData.extension,
        voucherKey: this.uploadVoucherData.voucherKey,
        voucherName: this.uploadVoucherData.fileName,
      };
      this.$post("/biz/order/voucher/save", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.uploadfileClose();
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 上传支付凭证 - 文件 - 回显
    getfileList(orderId) {
      this.$post("/biz/order/voucher/getInfo", { orderId })
        .then((ret) => {
          this.uploadVoucherData.voucherKey = ret.data.voucherKey || '';
          this.uploadVoucherData.voucherUrl = ret.data.voucherUrl || '';
          this.uploadVoucherData.fileName = ret.data.voucherName || '';
          this.uploadVoucherData.extension = ret.data.fileType || '';
        })
        .catch((err) => {
          return;
        });
    },
    // 上传支付凭证 - 文件 - 移除
    reomveExl() {
      this.uploadVoucherData.fileName = "";
      this.uploadVoucherData.voucherKey = "";
      this.uploadVoucherData.voucherUrl = "";
      this.uploadVoucherData.extension = "";
      this.$forceUpdate();
    },
    // 上传支付凭证 - 预览文件(弹框)
    lookExl() {
      this.uploadVoucherDataPreviewFileData.dialogVisible = true;
      if (this.uploadVoucherData.extension == "PDF") {
        this.$nextTick(() => {
          pdf.embed(this.uploadVoucherData.voucherUrl, "#pdf-cert1");
        });
      } else {
        this.uploadVoucherDataPreviewFileData.ImgSrc = this.uploadVoucherData.voucherUrl;
      }
    },
    // 上传支付凭证 - 预览文件(弹框) - 关闭
    uploadLodClose() {
      this.uploadVoucherDataPreviewFileData = this.$options.data().uploadVoucherDataPreviewFileData;
    },
    // 申请开票
    applyForInvoicing(row) { },
    // 列表高度计算
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  // 离开这个页面也要清除
  beforeDestroy() {
    clearInterval(this.getInfosSetInterval); // 清除定时器
    this.getInfosSetInterval = null;
  },
  watch: {},
};
</script>
<style lang="less" scoped>
#qrcode {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 20px;
}

.jxzf_1 {
  font-size: 16px;
  margin-bottom: 10px;
}

.jxzf_2 {
  span {
    color: red;
    font-size: 16px;
    font-weight: 600;
  }

  margin-bottom: 10px;
}

.jxzf_3 {
  margin-bottom: 20px;
}

.jxzf_4 {
  text-align: center;
  margin-top: 20px;
  color: #8b8b8b;
}

.el-upload {
  height: 180px !important;
}

.upload-btn {
  height: 180px;
  margin: 20px 0;
}
.text_1 {
  font-size: 22px;
  font-weight: 600;
  color: red;
}
</style>
